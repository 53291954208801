import {NgOptimizedImage, registerLocaleData} from '@angular/common'
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom
} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatButtonModule} from '@angular/material/button'
import {
  MatCardActions,
  MatCardContent,
  MatCardTitle
} from '@angular/material/card'
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  MatLabel,
  MatSuffix
} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatMenuModule} from '@angular/material/menu'
import {MatRadioGroup} from '@angular/material/radio'
import {MatSliderModule} from '@angular/material/slider'
import {
  MatStepLabel,
  MatStepperNext,
  MatStepperPrevious
} from '@angular/material/stepper'
import {BrowserModule} from '@angular/platform-browser'
import {provideAnimations} from '@angular/platform-browser/animations'
import {provideRouter, Router, withHashLocation} from '@angular/router'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import routes from './app-routes'
import {authInterceptor} from './application/auth.interceptor'
import {WINDOW, windowProvider} from './application/injection-tokens'
import {responseInterceptor} from './application/response.interceptor'
import {routeResolver} from './application/route-resolver'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: WINDOW, useFactory: windowProvider},
    importProvidersFrom(BrowserModule, MatMenuModule, MatIconModule, MatButtonModule, MatLabel, MatInput, ReactiveFormsModule, MatStepperNext, ThemeModule, MatRadioGroup, MatStepperPrevious, MatStepLabel, MatCardContent, MatCardTitle, MatCardActions, MatSuffix, MatSliderModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatFormFieldModule, NgOptimizedImage),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    /**
     * Conditionally initializes `bootstrap` only for the `/admin` route,
     */
    {
      provide: APP_INITIALIZER,
      useFactory: routeResolver,
      deps: [ConfigService, Router, WINDOW],
      multi: true
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([authInterceptor, responseInterceptor])
    ),
    provideAnimations(),
    provideRouter(routes,
      withHashLocation())
  ]
}